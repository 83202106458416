package style

import common.CSSShadow
import common.QuickStyleProperty.myBoxShadow
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

object StyleCommon : StyleSheet(AppStylesheet) {

    val sectionContainer by style {
        property("margin-left", "auto")
        property("margin-right", "auto")
        boxSizing("border-box")
//        marginAuto()
        paddingLeft(22.px)
        paddingRight(22.px)
        maxWidth(1276.px)

        paddingTop(96.px)
        paddingBottom(96.px)

        media(mediaMaxWidth(640.px)) {
            self style {
                paddingTop(12.px)
                paddingBottom(12.px)
                maxWidth(100.percent)
                paddingLeft(6.px)
                paddingRight(6.px)
            }
        }

        media(mediaMaxWidth(1276.px)) {
            self style {
                maxWidth(996.px)
                paddingLeft(styleConst.paddingSectionMobile)
                paddingRight(styleConst.paddingSectionMobile)
            }
        }

        media(mediaMaxWidth(1000.px)) {
            self style {
                maxWidth(100.percent)
                paddingLeft(styleConst.paddingSectionMobile)
                paddingRight(styleConst.paddingSectionMobile)
            }
        }
    }

    val newMorphDownGray by style {
        myBoxShadow(
            CSSShadow(-2.px, -2.px, 3.px, color = rgba(255, 255, 255, 0.3), inner = true),
            CSSShadow(2.px, 2.px, 3.px, color = rgba(0, 0, 0, 0.4), inner = true)
        )
        backgroundColor(stylePalette.gray)
        color(stylePalette.white)
    }
    val newMorphUpLightGray by style {
        myBoxShadow(
            CSSShadow(-3.px, -3.px, 5.px, color = rgb(255, 255, 255)),
            CSSShadow(3.px, 3.px, 5.px, color = rgba(0, 0, 0, 0.3))
        )
    }

    val itemClient by style {
        boxSizing("border-box")
        flexGrow(0)
        flexShrink(1)
        flexBasis(33.333.percent - 10.pt)
        media(mediaMaxWidth(1640.px)) {
            self style {
                flexBasis(50.percent - 10.pt)
            }
        }
        media(mediaMaxWidth(740.px)) {
            self style {
                flexBasis(100.percent)
            }
        }
    }

    val headersLight by style {
        padding(5.px)
        width(100.percent)
        textAlign("center")
        color(rgb(230, 230, 230))
        fontFamily("Roboto Slab")
        fontSize(23.px)
    }

    val dropDownContentBox by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        columnGap(10.pt)
        rowGap(5.pt)
    }

    val dropDownContentBoxDecor by style {
//        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        myBoxShadow(
            CSSShadow(3.px, 3.px, 5.px, 0.px, rgba(0, 0, 0, 0.5)),
            CSSShadow(3.px, 3.px, 20.px, 0.px, rgba(0, 0, 0, 0.19))
        )
        /*
                property(
                    "box-shadow",
                    "3px 3px 6px 2 rgba(0, 0, 0, 0.5), 4px 4px 12px 2 rgba(0, 0, 0, 0.19)"
                )
        */
        padding(10.px)
        backgroundColor(rgb(230, 230, 230))
    }

    val show by style {
        display(DisplayStyle.Block)
    }

    val noShow by style {
        display(DisplayStyle.None)
    }

    val simplyText by style {
        padding(5.px)
        color(rgb(0, 0, 0))
        fontFamily("Roboto Slab")
        fontSize(18.px)
    }


    @OptIn(ExperimentalComposeWebApi::class)
    val positionCenterVChild by style {
        position(Position.Absolute)
        top(50.percent)
        transform {
            translate((0).percent, (-50).percent)
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val positionCenterHChild by style {
        position(Position.Absolute)
        left(50.percent)
        transform {
            translate((-50).percent, 0.percent)
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val positionCenterHVChild by style {
        position(Position.Absolute)
        left(50.percent)
        top(50.percent)
        transform {
            translate((-50).percent, (-50).percent)
        }
    }

    val elementDisabled by style {
        self + disabled style {
            opacity(0.5)
        }
    }

    val headerBlock by style {
        padding(5.px)
        width(100.percent)
        textAlign("center")
        color(Color.darkgray)
        fontFamily(styleConst.mainFontFamily)
        fontSize(23.px)
    }

    val buttonBack by style {
        flex("initial")
        fontFamily("Roboto Slab")
        padding(8.px, 8.px, 8.px, 8.px)
        fontSize(15.px)
        property("text-transform", "uppercase");
        backgroundColor(rgb(71, 71, 71))
        color(rgb(255, 255, 255))
        borderWidth(0.px)
        borderRadius(10.px)
        self + hover style {
            backgroundColor(stylePalette.gray)// stylePalette.gray)
        }
    }

    val pagination by style {
        color(rgb(255, 255, 255))
        property("float", "left")
        padding(8.px, 16.px)
        textDecoration("none")
        self + hover style {
            borderRadius(8.px)
            backgroundColor(rgb(208, 208, 208))
            color(rgb(0, 0, 0))
        }
    }
    val paginationPoint by style {
        color(rgb(255, 255, 255))
        property("float", "left")
        padding(8.px, 16.px)
        textDecoration("none")
    }
    val inputText by style {
        fontSize(20.px)
        color(rgb(47, 47, 47))
        padding(8.px)
        backgroundColor(rgb(225, 225, 255))
        border(2.px, LineStyle.Solid, rgb(79, 79, 79))
        borderRadius(9.px)
        fontFamily(styleConst.mainFontFamily)
        self + hover style { // self is a selector for `container`
            backgroundColor(rgb(205, 205, 255))
            border(2.px, LineStyle.Solid, rgb(47, 47, 47))
            cursor("text")
        }
        self + focus style { // self is a selector for `container`
            backgroundColor(rgb(205, 205, 255))
            border(2.px, LineStyle.Solid, rgb(47, 47, 47))
            cursor("text")
        }
    }
    val inputTextHeaders by style {}
    val rtIconButton by style {}
    val smallTextInfo by style {}
    val rtMainBlock by style {
        whiteSpace("normal")
        fontFamily(styleConst.mainFontFamily)
        property("word-wrap", "break-all")
        property("overflow-wrap", "anywhere")
    }
    val rtView by style {
        whiteSpace("normal")
        property("word-wrap", "break-all")
        property("overflow-wrap", "anywhere")

        color(stylePalette.mainTextColor)
        fontFamily(styleConst.mainFontFamily)
        fontSize(20.px)
    }

    val paginationActiv by style {
        property("float", "left")
        padding(8.px, 16.px)
        textDecoration("none")
        borderRadius(8.px)
        backgroundColor(rgb(76, 175, 80))
        color(rgb(255, 255, 255))
    }

}