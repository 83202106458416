package common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.w3c.dom.HTMLElement
import style.StyleModalWindow
import style.stylePalette

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun modalWindow(modal: ModalContent, visible: MutableState<Boolean>) {
    Div({
        classes(StyleModalWindow.hystmodal)
        classes(if (visible.value) StyleModalWindow.hystmodalActive else StyleModalWindow.hystmodalDeactive)
        style {
            fontFamily("Roboto Slab")
            backgroundColor(Color.black)
        }
    }) {
        Div({
            classes(StyleModalWindow.hystmodal__wrap)
            onClick {
                val clickObj = it.target as HTMLElement
                if (clickObj.classList.contains(StyleModalWindow.hystmodal__wrap).not()) return@onClick
                if (modal.closableBack){
                    modalContent?.closeFun()
                    visible.value = false
                    modalContent = null
                }
            }
        }) {
            Div({
                classes(StyleModalWindow.hystmodal__window)
                classes(if (visible.value) StyleModalWindow.hystmodalActive else StyleModalWindow.hystmodalDeactive)
//                attr("role", "dialog")
//                attr("aria-modal", "true")
//                onClick {
//                    console.log("div Window")
//                }
                style {
                    if (modal.fullscreen) {
                        maxWidth(100.percent)
                        width(100.percent)
                        height(100.percent)
                        borderRadius(0.px)
                        padding(0.px)
                        margin(0.px)
                    }
                }
            }) {
                if (modal.fullscreen.not()) Button({
                    classes(StyleModalWindow.hystmodalClose)
//                    attr("data-hystclose", "")
                    onClick {
                        console.log("Butt Close")
                        modalContent?.closeFun()
                        modalContent = null
                        visible.value = false
                    }
                }) {
                    I({
                        classes(
                            "fa", "fa-xmark", //"fa-beat",
//                            StyleTrainingRoom.chevron,
                        )
                        style {
                            position(Position.Absolute)
                            fontSize(30.px)
                            transform {
                                translate((-50).percent, (-50).percent)
                            }
                            color(stylePalette.red)
                        }
                    })
                }
                Div({
//                    classes("contentModal")
//                    id("contentModal")
                }) {
                    modal.drawModalContent()
                }
            }
        }
    }
//    }
}


abstract class ModalContent() {

    open val fullscreen = false
    open val closableBack = true

    open fun closeFun() {}

    @Composable
    abstract fun drawModalContent()
}