package common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import common.QuickStyleProperty.borderBox
import common.QuickStyleProperty.objectFit
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import text.myText
import kotlin.js.Date

val phoneDevice = window.innerWidth < 700

fun getCurrentDateUTC(): Long = Date().let { Date(it.getFullYear(), it.getMonth(), it.getDate()).getTime() }
    .toLong() - Date().getTimezoneOffset() * 60000L

fun getCurrentDateTimeUTC(): Long = Date().getTime().toLong() - Date().getTimezoneOffset() * 60000L
val visibleModalWindow = mutableStateOf(false)

var modalContent by mutableStateOf<ModalContent?>(null)

fun closeModadWindow() {
    visibleModalWindow.value = false
    modalContent = null
}

fun openModalWindow(window: ModalContent) {
    modalContent = window
    visibleModalWindow.value = true
}

fun openModalWindow(
    buttOk: Boolean = false,
    closeBack: Boolean = false,
    fullscreen: Boolean = false,
    rezCloseFun: () -> Unit = {},
    content: @Composable () -> Unit
) {

    class tempModalWindow() : ModalContent() {
        override val closableBack = closeBack
        override fun closeFun() {
            rezCloseFun()
        }

        override val fullscreen = fullscreen

        @Composable
        override fun drawModalContent() {
            content()
            if (buttOk) CenterDiv {
                Button({
//                    classes(StyleClientRoom.buttonDone)
                    style {
                        padding(8.px, 30.px)
//                    width(100.px)
                    }
                    onClick {
                        rezCloseFun()
                        closeModadWindow()
                    }
                }) {
                    Text("Ok")
                }
            }
        }
    }
    modalContent = tempModalWindow()
    visibleModalWindow.value = true
}

val screenshots = listOf<String>(
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_01.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_02.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_03.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_04.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_05.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_06.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_07.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_08.jpg",
    "https://FitConstructorImg.b-cdn.net/other/Itreumme/Screenshot_Itreumme_09.jpg"
)

val listTabs = screenshots.map { srcImg ->
    MyTabs("tab1") {
        CenterDiv({
            style {
                borderBox()
                height(window.innerHeight.px)
                width(100.percent)
                lineHeight("0")
                backgroundColor(Color.black)
                position(Position.Relative)
            }
        }) {
            Img(srcImg) {
                style {
                    position(Position.Relative)
                    borderBox()
                    maxHeight(window.innerHeight.px)
//                                    height(100.percent)
                    width(100.percent)
                    objectFit(ObjectFit.ScaleDown)
                }
                onClick {
                    closeModadWindow()
                }
                onDragStart {
                    it.preventDefault()
                }
            }
        }
    }
}

var onresizeClientComplexSlider: ()->Unit = {}
val sliderScreenShots = SliderPanel(listTabs){
//    console.log("window.onresize set")
    onresizeClientComplexSlider = it
}



val loadFiles = listOf<FileDownload>(
    FileDownload(
        listOf("fa-brands", "fa-windows"),
        "loadWindowsVersion",
        { "MSI ${myText.forText} Windows" },
        "https://FitConstructorImg.b-cdn.net/other/Itreumme/Itreumme-1.0.003.msi"
    ),
    FileDownload(
        listOf("fa-brands", "fa-android"),
        "loadAndroidVersion",
        { "APK ${myText.forText} Android" },
        "https://FitConstructorImg.b-cdn.net/other/Itreumme/Itreumme.apk"
    ),
    FileDownload(
        listOf("fa-brands", "fa-apple"),
        "loadMacOSVersion",
        { "DMG ${myText.forText} macOS" },
        "https://FitConstructorImg.b-cdn.net/other/Itreumme/Itreumme-1.0.003.dmg"
    )
)

class FileDownload(val icon: List<String>, val action_label: String, val label: () -> String, val URL: String)