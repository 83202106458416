import androidx.compose.runtime.*
import common.*
import common.QuickStyleProperty.borderBox
import common.QuickStyleProperty.myNonClick
import common.QuickStyleProperty.myTextNonSelect
import common.QuickStyleProperty.objectFit
import common.QuickStyleProperty.zIndex
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable
import style.AppStylesheet
import style.StyleCustomProperties
import style.styleConst
import style.stylePalette
import text.myText
import text.text_En
import text.text_Ru

fun main() {

    window.onresize = { event ->
        console.log("window.onresize")
        onresizeClientComplexSlider()
    }

    renderComposable(rootElementId = "root") {
        Style(AppStylesheet)
        modalContent?.let {
            modalWindow(it, visibleModalWindow)
        }
        MainContentLayout {
            mainTitle()
            MyContainerInSection {
                mainDescription()
                buttonLocale()
                screenshotsBlock()
                loadInfo()
                loadFileBlock()
                epilog()
            }
        }
        styleOldGlass()
    }
}


@Composable
private fun mainTitle() {
    CenterDiv {
        Img("https://FitConstructorImg.b-cdn.net/other/itreumme.png") {
            style {
                myNonClick()
                width(if (phoneDevice) 60.percent else 30.percent)
                paddingTop(20.pt)
            }
        }
    }
}

@Composable
private fun styleOldGlass() {
    Div({
        style {
            position(Position.Fixed)
        }
    }) {
        Img(
            if (window.innerWidth > window.innerHeight) "https://FitConstructorImg.b-cdn.net/other/back_horiz_1.png"
            else "https://FitConstructorImg.b-cdn.net/other/back_vert_1.png"
        ) {
            style {
                position(Position.Fixed)
                top(0.px)
                left(0.px)
                objectFit(ObjectFit.Cover)
                zIndex(13)
                overflowY("visible")
                myNonClick()
                width(100.percent)
                height(window.outerHeight.px * 1.10)
            }
        }
    }
}

@Composable
private fun mainDescription() {
    textBlockStyle {
        Text(myText.mainText_1)
        Br { }
        Br { }
        Text(myText.mainText_2)
        Br { }
        Br { }
        Text(myText.mainText_3)
    }
}

@Composable
private fun loadInfo() {
    textBlockStyle {
        Text(myText.loadInfo)
        Br {}
        Br {}
        FlexRowDivParent({
            style {
                borderBox()
                maxWidth(100.percent)
            }
        }) {

            CenterDiv({
                style {
                    minWidth(25.pt)
                    minHeight(25.pt)
                    myTextNonSelect()
                    property("pointer-events", "none")
                    flex("initial")
                }
            }) {
                I({
                    classes(listOf("fa-brands", "fa-windows"))
                })
            }
            P({
                style {
                    flex("auto")
                    flexShrink(1)
                    borderBox()
                    overflowX("hidden")
                    property("overflow-wrap", "break-word")
                    flexGrow(1)
                    fontFamily(styleConst.secondFontFamily)
                    fontSize(12.pt)
                    color(stylePalette.fontUnactiveColor)
                }
                lang(if (myText == text_En) "en" else "ru")
            }) {
                Text(myText.loadInfo_Windows)
            }
        }
        Br {}
        FlexRowDivParent({
            style {
                borderBox()
                maxWidth(100.percent)
            }
        }) {
            CenterDiv({
                style {
                    minWidth(25.pt)
                    minHeight(25.pt)
                    myTextNonSelect()
                    property("pointer-events", "none")
                    flex("initial")
                }
            }) {
                I({
                    classes(listOf("fa-brands", "fa-android"))
                })
            }
            CenterDiv {
                P({
                    style {
                        flex("auto")
                        flexShrink(1)
                        borderBox()
                        overflowX("hidden")
                        property("overflow-wrap", "break-word")
                        flexGrow(1)
                        fontFamily(styleConst.secondFontFamily)
                        fontSize(12.pt)
                        color(stylePalette.fontUnactiveColor)
                    }
                    lang(if (myText == text_En) "en" else "ru")
                }) {
                    Text(myText.loadInfo_Android)
                }
            }
        }
        Br {}
        FlexRowDivParent({
            style {
                borderBox()
                maxWidth(100.percent)
            }
        }) {
            CenterDiv({
                style {
                    minWidth(25.pt)
                    minHeight(25.pt)
                    myTextNonSelect()
                    property("pointer-events", "none")
                    flex("initial")
                }
            }) {
                I({
                    classes(listOf("fa-brands", "fa-apple"))
                })
            }
            CenterDiv {
                P({
                    style {
                        flex("auto")
                        flexShrink(1)
                        borderBox()
                        overflowX("hidden")
                        property("overflow-wrap", "break-word")
                        flexGrow(1)
                        fontFamily(styleConst.secondFontFamily)
                        fontSize(12.pt)
                        color(stylePalette.fontUnactiveColor)
                    }
                    lang(if (myText == text_En) "en" else "ru")
                }) {
                    Text(myText.loadInfo_MacOS)
                }
            }
        }
        Br {}
        Text(myText.loadInfo_backup)
    }
}

@Composable
private fun epilog() {
    textBlockStyle {
        Div({
            lang(if (myText == text_En) "en" else "ru")
        }) {
            Text(myText.epilog_1)
            Br {}
            Br {}
            Text(myText.epilog_2)
        }
        contactBlock()
    }
}

@Composable
private fun textBlockStyle(text: @Composable () -> Unit) {
    CenterDiv({
        style {
            fontSize(16.pt)
            fontWeight(500)
            color(Color.darkgreen)
            borderBox()
            maxWidth(100.percent)
            fontFamily(styleConst.mainFontFamily)
        }
    }) {
        CutBorderBox(15, 1, "linear-gradient(45deg,white,transparent,white)", lineHeightZero = false, {
            style {
                borderBox()
                maxWidth(100.percent)
            }
        }) {
            Div({
                style {
                    backgroundColor(rgba(0, 0, 0, 0.7))
                    color(Color.white)
                    borderBox()
                    maxWidth(100.percent)
                    padding(18.px)
                }
                lang(if (myText == text_En) "en" else "ru")
            }) {
                text()
            }
        }
    }

}

@Composable
private fun buttonLocale() {
    CutBorderBox(10, 1,
//                    "linear-gradient(45deg,white,white)",
        "linear-gradient(45deg,#edeec755,#edeec755)",
        lineHeightZero = false, {
//                    CenterDiv({
            style {
                position(Position.Absolute)
                top(5.pt)
                right(5.pt)
                fontSize(15.pt)
                color(Color.black)
                padding(2.pt)
                textAlign("center")
                width(40.px)
                zIndex(15)
                myTextNonSelect()
                fontFamily(styleConst.mainFontFamily)
            }
            onClick {
                myText = if (myText == text_En) text_Ru else text_En
            }
        }) {
        Div({
            style {
                padding(3.pt)
            }
        }) {
            Text(if (myText == text_En) "En" else "Ru")
        }
    }
}


@Composable
private fun loadFileBlock() {
    Div(
        attrs = {
//            classes(StyleVideoBlock.myVideoBlock)//,StyleTrainingRoom.contentCard, if (closeCard) StyleTrainingRoom.closeCard else StyleTrainingRoom.openCard) //WtRows.wtRow,
            style {
                display(DisplayStyle.Flex)
                flexWrap(FlexWrap.Wrap)

                borderBox()

                columnGap(10.pt)
                rowGap(10.pt)

                margin(30.pt, 0.pt)
                marginRight(-10.pt)
                padding(10.pt)
                zIndex(14)
                position(Position.Relative)

                lineHeight("0")
            }
        }
    ) {
        loadFiles.forEach { fileDownload ->
            CenterDiv({
                classes(StyleCustomProperties.flexBasis_1_3_10pt)
                style {
                    position(Position.Relative)
                    maxHeight(150.pt)
                }
            }) {
                A(href = fileDownload.URL, {
//                A(href = "Itreumme.apk",{
                    attr("download", "")
                    attr("target", "_blank")
                    style {
                        color(Color.black)
                        fontFamily(styleConst.mainFontFamily)
                    }
                    onClick {
                        myGtag("event", fileDownload.action_label, "button", "click")
                        console.log(fileDownload.action_label)
                    }
                }) {
                    CutBorderBox(10, 1,
//                    "linear-gradient(45deg,white,white)",
//                        "linear-gradient(45deg,white,red)",
                        "linear-gradient(45deg,#edeec799,#edeec799)",
                        lineHeightZero = false, {
//                    CenterDiv({
                            style {
                                position(Position.Relative)
                                fontSize(17.pt)
                                color(Color.black)
                                padding(5.pt)
                                height(50.pt)
                                textAlign("center")
                                width(90.percent)
                                zIndex(14)
                            }
                        }) {
                        FlexRowDivParent {
                            CenterDiv({
                                style {
                                    minWidth(30.pt)
                                    minHeight(30.pt)
                                    myTextNonSelect()
                                    property("pointer-events", "none")
                                }
                            }) {
                                I({
                                    classes(fileDownload.icon)
                                })
                            }
                            CenterDiv({
                                style {
                                    height(50.px)
                                    width(200.px)
                                    fontFamily(styleConst.mainFontFamily)
//                                myTestBorder()
                                }
                            }) {
                                Text(fileDownload.label())
                            }
                        }
                    }
                }


            }
        }
    }
}


@Composable
private fun screenshotsBlock() {
    Div(
        attrs = {
//            classes(StyleVideoBlock.myVideoBlock)//,StyleTrainingRoom.contentCard, if (closeCard) StyleTrainingRoom.closeCard else StyleTrainingRoom.openCard) //WtRows.wtRow,
            style {
                display(DisplayStyle.Flex)
                flexWrap(FlexWrap.Wrap)

                borderBox()

                columnGap(10.pt)
                rowGap(10.pt)

                margin(30.pt, 0.pt)
                marginRight(-10.pt)
                padding(10.pt)

                lineHeight("0")
            }
        }
    ) {
        screenshots.forEachIndexed { index, srcImg ->
            CenterDiv({
                classes(StyleCustomProperties.flexBasis_2_3_10pt, StyleCustomProperties.hoverTranslate)
                style {
                    lineHeight("0")
                    maxHeight(150.pt)
                }
                onClick {
                    sliderScreenShots.setSlide(index)
                    openModalWindow(buttOk = false, fullscreen = true) {
                        sliderScreenShots.sliderPanel()
                        CenterDiv({
                            style {
//                            backgroundColor(Color.black)
                                position(Position.Fixed)
                                bottom(0.px)
                                opacity(0.8)
                                width(100.percent)
                                columnGap(20.pt)
                                padding(15.px)
                                borderBox()
                            }
                        }) {
                            CutBorderBox(8, 1,
                                "linear-gradient(0deg,#fff,#fff)",
                                lineHeightZero = true, {
                                    style {
                                        if (sliderScreenShots.slideIndex == 0) opacity(0.0)
                                    }
                                    onClick {
                                        sliderScreenShots.prev()
                                    }
                                }) {
                                CenterDiv({
                                    style {
//                                    width(200.px)
//                                    height(40.px)
                                        padding(10.px, 20.px)
                                        backgroundColor(Color.black)
                                        color(Color.white)
                                        fontFamily(styleConst.mainFontFamily)
                                        fontSize(18.px)
                                        myTextNonSelect()
                                    }
                                }) {
                                    I({
                                        classes(listOf("fa-solid", "fa-chevron-left"))
                                    })
//                            Text("<")
                                }
                            }
                            CutBorderBox(8, 1,
                                "linear-gradient(0deg,#fff,#fff)",
                                lineHeightZero = true, {
                                    style {
                                    }
                                    onClick {
                                        closeModadWindow()
                                    }
                                }) {
                                CenterDiv({
                                    style {
//                                    width(200.px)
//                                    height(40.px)
                                        padding(10.px, 20.px)
                                        backgroundColor(Color.black)
                                        color(Color.white)
                                        fontFamily(styleConst.mainFontFamily)
                                        fontSize(18.px)
                                        myTextNonSelect()
                                    }
                                }) {
                                    I({
                                        classes(listOf("fa-solid", "fa-xmark"))
                                    })
//                                    Text("Скрыть")
                                }
                            }
                            CutBorderBox(8, 1,
                                "linear-gradient(0deg,#fff,#fff)",
                                lineHeightZero = true, {
                                    style {
                                        if (sliderScreenShots.slideIndex == screenshots.count() - 1) opacity(0.0)
                                    }
                                    onClick {
                                        sliderScreenShots.next()
                                    }
                                }) {
                                CenterDiv({
                                    style {
//                                    width(200.px)
//                                    height(40.px)
                                        padding(10.px, 20.px)
                                        backgroundColor(Color.black)
                                        color(Color.white)
                                        fontFamily(styleConst.mainFontFamily)
                                        fontSize(18.px)
                                        myTextNonSelect()
                                    }
                                }) {
                                    I({
                                        classes(listOf("fa-solid", "fa-chevron-right"))
                                    })
//                                    Text(">")
                                }
                            }
                        }
                    }
                }
            }) {
                Img(srcImg) {
                    style {
                        borderBox()
                        height(100.percent)
                        width(100.percent)
                        objectFit(ObjectFit.ScaleDown)
                    }
                }
            }
        }
    }
}

@Composable
private fun contactBlock() {
    FlexRowDivParent({
        style {
            marginTop(10.pt)
            columnGap(10.pt)
        }
    }) {
        CutBorderBox(10, 2,
            "linear-gradient(0deg,#b4c2c7,#b4c2c733,#b4c2c7)",
            lineHeightZero = true, {
                style {
                }
            }) {
            Img("https://FitConstructorImg.b-cdn.net/other/Itreumme/avatarMain.jpg") {
                style {
                    borderBox()
                    height(70.pt)
                    width(70.pt)
                    objectFit(ObjectFit.Cover)
                }
            }
        }
        CenterDiv({
            style {
                flexDirection(FlexDirection.Column)
                alignItems(AlignItems.Start)
            }
        }) {
            P({
                style {
                    fontFamily(styleConst.secondFontFamily)
                    fontSize(18.pt)
                    color(Color.white)
                }
            }) {
                Text(myText.fio)
            }
            P({
                style {
                    fontFamily(styleConst.secondFontFamily)
                    fontSize(14.pt)
                    color(stylePalette.fontUnactiveColor)
                }
            }) {
                Text("ragefn@gmail.com")
            }
        }
    }
}

