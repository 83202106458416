package style

import org.jetbrains.compose.web.css.*

object stylePalette : ColorResWhite()
object styleConst : SMainConst()

val ColorResFirst = SMainPalette()

open class ColorResWhite : SMainPalette(
    bodyColor = Color.white,
    headersTextColor = Color.black,
    textColor_1 = Color.darkgray,
    mainTextColor = Color.black,
    mainTextColorInv = Color.white,
    siteHeadersColor = rgb(255,255,255)
)

open class SMainConst (
    val titleFontFamily: String = "TT Norms Pro",
    val mainFontFamily: String = "Times New Roman", //"HelveticaNeueCyr",//, sans-serif" //"Roboto Slab"
    val secondFontFamily: String = "Roboto Slab",
    val fontHeaderSize: CSSNumeric = 24.pt,
    val fontCommonSize: CSSNumeric = 12.pt,
    val fontSmallSize: CSSNumeric = 10.pt,
    val fontSubheaderSize: CSSNumeric = 16.pt,
    val fontHeaderWeight: Int = 700,
    val fontCommonWeight: Int = 500,
    val fontSmallWeight: Int = 500,
    val fontSubheaderWeight: Int = 700,
    val borderWidthCommon: CSSLengthValue = 1.pt,
    val borderRadiusCommon: CSSNumeric = 15.pt,
    val paddingCommon: CSSNumeric = 10.pt,
    val paddingSmall: CSSNumeric = 3.pt,
    val paddingSectionMobile: CSSNumeric = 10.pt
)
open class SMainPalette (

    val mainThemeColor: CSSColorValue = Color("#edeec7"),
    val mainThemeColor_2: CSSColorValue = Color("#edeec755"),

    val transparent: CSSColorValue =  rgba(0,0,0,0),
    val gray: CSSColorValue = rgb(127,127,127),
    val lightGray: CSSColorValue = rgb(227,227,227),
    val darkGray: CSSColorValue = rgb(71,71,71),
    val black: CSSColorValue = rgb(0,0,0),
    val white: CSSColorValue = rgb(255,255,255),
    val red: CSSColorValue = rgb(255,0,0),
    val darkRed: CSSColorValue = rgb(130,0,0),
    val green: CSSColorValue = rgb(0,255,0),
    val darkGreen: CSSColorValue = rgb(0,185,0),
    val darkBlueTrans: CSSColorValue = rgba(50,50,205,0.5),
    val blue: CSSColorValue = rgb(0,0,255),
    val darkBlue: CSSColorValue = rgb(0,0,130),

    val markerGray: CSSColorValue = rgba(71, 71, 71, 0.7),
    val markerRed: CSSColorValue = rgba(255, 0, 0, 0.7),
    val markerGreen: CSSColorValue = rgba(0, 255, 0, 0.7),
    val markerYellow: CSSColorValue = rgba(255, 234, 0, 0.7),

    val bodyColor: CSSColorValue = Color("#323236"),
    val bodyModalColor: CSSColorValue = Color.lightgray,
    val backgroundInputText: CSSColorValue = Color.white,
    val textColor_1: CSSColorValue = white,
    val mainTextColor: CSSColorValue = white,
    val textSmallInfo: CSSColorValue = gray,
    val mainTextColorInv: CSSColorValue = black,
    val headersTextColor: CSSColorValue = white,
    val siteHeadersColor: CSSColorValue = black,



    val fontHeaderColorInv: CSSColorValue = Color("#ffffff"),
    val fontHeaderColor: CSSColorValue = Color("#333333"),
    val fontCommonColor: CSSColorValue = Color("#808080"),
    val fontMiddleColor: CSSColorValue = Color("#bbbbbb"),
    val fontUnactiveColor: CSSColorValue = Color("#dddddd"),

    val myRed: CSSColorValue = Color("#cc3333"),
//    val myRed: CSSColorValue = Color("#e41c24"),
//    val myNewRed: CSSColorValue = Color("#39ac4a"),
    val myNewRed: CSSColorValue = Color("#ce3f40"),
//    val myOrange: CSSColorValue = Color("#f1a417"),
    val myOrange: CSSColorValue = Color("#ff9900"),
    val myBlue: CSSColorValue = Color("#0066cc"),
//    val myBlue: CSSColorValue = Color("#0071b9"),
    val myGreen: CSSColorValue = Color("#339933")
//    val myGreen: CSSColorValue = Color("#39ac4a")
)
/*
{
    constructor(parent: SMainPalette) : this(
        transparent = parent.transparent,
                gray = parent.gray,
                lightGray = parent.lightGray,
                darkGray = parent.darkGray,
                black = parent.black,
                white = parent.white,
                red = parent.red,
                darkRed = parent.darkRed,
                green = parent.green,
                darkGreen = parent.darkGreen,
                darkBlueTrans = parent.darkBlueTrans,
                blue = parent.blue,
                darkBlue = parent.darkBlue,
                markerGray = parent.markerGray,
                markerRed = parent.markerRed,
                markerGreen = parent.markerGreen,
                markerYellow = parent.markerYellow,
                bodyColor = parent.bodyColor,
                headersColor = parent.headersColor
    ) {

    }
}*/
