package style

import common.CSSShadow
import common.QuickStyleProperty.myBoxShadow
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

private fun flexBasis_Custom(cssBuilder: CSSBuilder, min: Int, max: Int, columnGap: CSSNumeric) {
    cssBuilder.apply {
        flexBasis((100.0 / max.toFloat()).percent - columnGap)

        val shag = 700 / min
//        val count = 1920/shag
        for (i in max downTo min) {
            media(mediaMaxWidth(i * shag.px)) {
                self style {
                    flexBasis((100.0 / i.toFloat()).percent - columnGap)
                }
            }
        }
    }
}

object StyleCustomProperties : StyleSheet(AppStylesheet) {


    val hoverOpacity by style {
        opacity(0)
        hover(self) style {
            opacity(1)
        }
    }

    val flexBasis_3_8_5pt by style {
        flexBasis_Custom(this, 3, 8, 5.pt)
    }
    val flexBasis_1_3_10pt by style {
        flexBasis_Custom(this, 1, 3, 10.pt)
    }
    val flexBasis_2_3_10pt by style {
        flexBasis_Custom(this, 2, 3, 10.pt)
    }
    val flexBasis_2_6_5pt by style {
        flexBasis_Custom(this, 2, 6, 5.pt)
    }
    val flexBasis_3_8_10pt by style {
        flexBasis_Custom(this, 3, 8, 10.pt)
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val hoverTranslate by style {
        hover(self) style {
            transform {
                translate((-2).px, (-2).px)
            }
            myBoxShadow(CSSShadow())
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val allAnimation by style {
//        height(30.pt)
//        width(30.pt)
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
    }
}