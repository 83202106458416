package common

import androidx.compose.runtime.*
import common.QuickStyleProperty.borderBox
import common.QuickStyleProperty.marginWidthAuto
import common.QuickStyleProperty.myTestBorder
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Main
import org.w3c.dom.*
import style.StyleCommon
import style.stylePalette


var headerTop by mutableStateOf(0)
val headerHeight = 45
val widthSide = if (window.innerWidth < 700) window.innerWidth.px else 360.px
var widthSideNavPx: CSSSizeValue<out CSSUnitAbs> by mutableStateOf(if (window.innerWidth < 700) 0.px else widthSide)
var selectableNone by mutableStateOf(false)


@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun FlexRowDivParent(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    rowGap: CSSNumeric = 10.pt,
    columnGap: CSSNumeric = 10.pt,
    flexWrap: FlexWrap = FlexWrap.Nowrap,
    content: @Composable () -> Unit
) {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexWrap(flexWrap)
                columnGap(columnGap)
                rowGap(rowGap)
            }
            attrs?.invoke(this)
        }
    ) {
        content()
    }
}

@Composable
fun FlexColumnDivParent(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    rowGap: CSSNumeric = 10.pt,
    columnGap: CSSNumeric = 10.pt,
    flexWrap: FlexWrap = FlexWrap.Nowrap,
    content: @Composable () -> Unit
) {
    Div(
        attrs = {
            style {
                display(DisplayStyle.Flex)
                flexWrap(flexWrap)
                flexDirection(FlexDirection.Column)
                columnGap(columnGap)
                rowGap(rowGap)
            }
            attrs?.invoke(this)
        }
    ) {
        content()
    }
}

@Composable
fun FlexAutoDiv(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit
) {
    Div(
        attrs = {
            style {
                flex("auto")
            }
            attrs?.invoke(this)
        }
    ) {
        content()
    }
}

@Composable
fun FlexInitialDiv(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit
) {
    Div(
        attrs = {
            style {
                flex("initial")
            }
            attrs?.invoke(this)
        }
    ) {
        content()
    }
}


@Composable
fun CenterDiv(attrs: AttrBuilderContext<HTMLDivElement>? = null, content: @Composable () -> Unit) {
    Div({
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            justifyContent(JustifyContent.Center)
//            position(Position.Relative)
        }
        attrs?.invoke(this)
    }) {
            content()
    }
}

@Composable
fun CenterHeightDiv(attrs: AttrBuilderContext<HTMLDivElement>? = null, content: @Composable () -> Unit) {
    Div({
        attrs?.invoke(this)
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
//            justifyContent(JustifyContent.Center)
            position(Position.Relative)
        }
    }) {
            content()
    }
}






@Composable
fun Layout(content: @Composable () -> Unit) {
    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            height(100.percent)
            margin(0.px)
            boxSizing("border-box")
        }
    }) {
        content()
    }
}

private var prevScrollPos = 0.0

fun mainScrollTop() = document.getElementById("mainElement")?.scrollTop ?: 0.0
fun mainScrollTo(top: Double, animation: Boolean = false){
    val me = document.getElementById("mainElement")
    if (animation){
    me?.scrollTo(
        ScrollToOptions(
            top = top,  //+ (if(plusScrollTop) me.scrollTop else 0.0),
            behavior = ScrollBehavior.SMOOTH
        )
    )} else me?.scrollTo(0.0,top)
}

@Composable
fun MainContentLayout(content: @Composable (Int) -> Unit) {
    Main({
        style {
//            if (window.innerWidth > 700) marginLeft(widthSideNavPx)
            height(window.innerHeight.px)
//            height(100.percent)
            overflowY("auto")
            flex("1 0 auto")
            boxSizing("border-box")
            fontFamily("Roboto Slab")
//            paddingTop(headerHeight.px)
            if (selectableNone) property("user-select", "none")
            property("transition", "margin-left 0.3s")
            backgroundColor(stylePalette.mainThemeColor)
        }
        id("mainElement")
        onScroll {
            val me = document.getElementById("mainElement") as HTMLElement
            if (modalContent == null) {
                val currentScrollPos = me.scrollTop
                if (currentScrollPos >= 0) {
                    val dy = currentScrollPos - prevScrollPos

                    var tmpTop = headerTop - dy //if (prevScrollPos > currentScrollPos) headerTop + dy else
                    if (tmpTop < -headerHeight) tmpTop = (-headerHeight).toDouble()
                    if (tmpTop > 0) tmpTop = 0.0
                    if (widthSideNavPx == widthSide) headerTop = 0 else headerTop = tmpTop.toInt()
                    currentScrollPos.also { prevScrollPos = it }
                }
            } else {
                it.preventDefault()
            }
        }
    }) {
/*
        Div(
            attrs = {
                style {
                    height(headerHeight.px)
                    width(100.percent)
                }
            }
        )
*/

        content(window.innerHeight - headerHeight)
    }
}

@Composable
fun MyContainerInSection(attrs: AttrBuilderContext<HTMLDivElement>? = null, heightFull: Boolean = false, backgroundColor: CSSColorValue? = null, backgroundImage: String? = null, content: @Composable () -> Unit) {
    Div ({
        attrs?.invoke(this)
        style {
            myTestBorder(stylePalette.transparent)
//            myTestBorder(Color.lavender)
            marginWidthAuto()
            borderBox()
            width(100.percent)
            if (heightFull) height(100.percent)
            backgroundColor?.let {
                backgroundColor(it)
            }
            backgroundImage?.let {
                backgroundImage(it)
                backgroundRepeat( "no-repeat")
                backgroundAttachment( "fixed")
                backgroundSize("cover")

            }
        }
    }) {
        Div({
            classes(StyleCommon.sectionContainer)
            style {
                borderBox()
                if (heightFull) height(100.percent)
//                myTestBorder(Color.yellow)
//                overflowX ("hidden")
            }
        }) {
            content()
        }
    }
}