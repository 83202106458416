package text

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue

abstract class TextPattern {
    abstract val mainText_1: String
    abstract val mainText_2: String
    abstract val mainText_3: String
    abstract val loadInfo: String
    abstract val loadInfo_Windows: String
    abstract val loadInfo_Android: String
    abstract val loadInfo_MacOS: String
    abstract val loadInfo_backup: String
    abstract val epilog_1: String
    abstract val epilog_2: String
    abstract val contact: String
    abstract val fio: String
    abstract val forText: String
}

object text_En : TextPattern() {
    override val mainText_1: String =
        "\"Itreumme\" is an original project aimed at capturing life experiences and sharing the most valuable ones."
    override val mainText_2: String =
        " Similar to a character in a video game, individuals have two main resources they can flexibly manage: their time and their financial capital. Whether it's personal or material goals and plans, they can be allocated in advance or retrospectively to these two resources."
    override val mainText_3: String =
        "The Itreumme project proposes capturing experiences and creating plans just as it is done in computer games, where character skills, past, current, and future goals and tasks are often clearly recorded. Those who have once traveled the path and diligently documented it can greatly facilitate it for those who will follow."
    override val loadInfo: String =
        "Here you can download preview versions. The Desktop version has been most extensively developed, but it is still far from complete, and not everything is optimized for user-friendly experience. It is a technical version intended for developers."
    override val loadInfo_Windows: String = "To run Itreumme.exe from the installation folder (by default C:\\Program Files\\Itreumme), you may need to run it as an administrator. The data is stored in the user folder in the ItreummeData directory (typically C:\\Users\\{userName}\\ItreummeData)."
    override val loadInfo_Android: String = "You will need to allow installation from an unknown source."
    override val loadInfo_MacOS: String = "You will need to allow installation from an unknown source. The data is stored in the user folder in the ItreummeData directory (typically Users > {userName} > ItreummeData)."
    override val loadInfo_backup: String = "There is an option to create backups of data to your Google Drive."
    override val epilog_1: String =
        "Currently, work on the project has been temporarily suspended due to a lack of appropriate resources, but it will be resumed as soon as the opportunity arises."
    override val epilog_2: String =
        "If you find the project interesting and would like to contribute or participate, feel free to email me."
    override val contact: String = "Contacts"
    override val fio: String = "Andrey Morozov"
    override val forText: String = "for"
}

object text_Ru : TextPattern() {
    override val mainText_1: String =
        "\"Itreumme\" - это авторский проект, целью которого является фиксация жизненного опыта и возможность поделиться самым полезным из него."
    override val mainText_2: String =
        "Подобно персонажу компьютерной игры, у человека можно выделить два основных ресурса, которыми он может гибко распоряжаться: время и денежный капитал. Все цели и планы можно заранее или после их достижения распределить между этими ресурсами, будь то развитие навыков, личностное развитие или материальные блага."
    override val mainText_3: String =
        "Проект Itreumme предлагает фиксировать опыт и составлять планы так же, как это сделано в компьютерных играх, где четко фиксируются навыки персонажа, его цели и задачи, прошедшие, текущие и будущие. Те, кто однажды прошел этот путь и тщательно его зафиксировал, могут значительно облегчить его для тех, кто следует за ними."
    override val loadInfo: String =
        "Здесь вы можете скачать ознакомительные версии. Больше всего продвинута версия для компьютера, но и она все еще находится в разработке, и не все функции удобны для использования. Это техническая версия для разработчиков."
    override val loadInfo_Windows: String = "Запускать Itreumme.exe из папки установки (по умолчанию C:\\Program Files\\Itreumme). Может потребоваться запуск от имени администратора. Данные хранятся в папке пользователя в папке ItreummeData (~ C:\\Users\\{userName}\\ItreummeData)."
    override val loadInfo_Android: String = "Нужно будет разрешить установку из неизвестного источника."
    override val loadInfo_MacOS: String = "Нужно будет разрешить установку из неизвестного источника. Данные хранятся в папке пользователя в папке ItreummeData (~ Users > {userName} > ItreummeData)."
    override val loadInfo_backup: String = "Есть возможность делать резервные копии данных на свой Google Drive."
    override val epilog_1: String =
        "В настоящее время работа над проектом временно приостановлена из-за отсутствия необходимых ресурсов, но она будет возобновлена сразу, как только появится такая возможность."
    override val epilog_2: String =
        "Если вас заинтересовал проект, и вы хотите помочь или принять в нем участие, можете написать мне."
    override val contact: String = "Контакты"
    override val fio: String = "Андрей Морозов"
    override val forText: String = "для"
}

var myText by mutableStateOf<TextPattern>(text_En)